var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Data Gaji Tidak Tetap ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"employee_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"employee_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_id","reduce":function (employee_nip) { return employee_nip.id; },"placeholder":"Pilih Pegawai","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPegawai,"label":"name"},model:{value:(_vm.dataStudent.employee_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "employee_id", $$v)},expression:"dataStudent.employee_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal Efektif","label-for":"effective_date"}},[_c('validation-provider',{attrs:{"name":"effective_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"effective_date","state":errors.length > 0 ? false : null,"placeholder":"effective_date","type":"date"},model:{value:(_vm.dataStudent.effective_date),callback:function ($$v) {_vm.$set(_vm.dataStudent, "effective_date", $$v)},expression:"dataStudent.effective_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Komponen","label-for":"component_name"}},[_c('validation-provider',{attrs:{"name":"component_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"component_name","state":errors.length > 0 ? false : null,"placeholder":"Nama Komponen"},model:{value:(_vm.dataStudent.component_name),callback:function ($$v) {_vm.$set(_vm.dataStudent, "component_name", $$v)},expression:"dataStudent.component_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Referensi","label-for":"reference"}},[_c('validation-provider',{attrs:{"name":"reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","state":errors.length > 0 ? false : null,"placeholder":"Referensi"},model:{value:(_vm.dataStudent.reference),callback:function ($$v) {_vm.$set(_vm.dataStudent, "reference", $$v)},expression:"dataStudent.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nominal","label-for":"nominal"}},[_c('validation-provider',{attrs:{"name":"nominal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nominal","state":errors.length > 0 ? false : null,"placeholder":"Nominal","type":"number"},model:{value:(_vm.dataStudent.nominal),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nominal", $$v)},expression:"dataStudent.nominal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Deskripsi","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"Deskripsi"},model:{value:(_vm.dataStudent.description),callback:function ($$v) {_vm.$set(_vm.dataStudent, "description", $$v)},expression:"dataStudent.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }